html {
  --scroll-behavior: smooth;
  scroll-behavior: smooth;
}
.main-container {
  margin: 0 10px;
  border-radius: 16px;
  border: 1px solid #eeeeee;
}
.desktop {
  @media (max-width: 768px) {
    display: none;
  }
}
.mobile {
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
}
