.login-page {
  display: flex;

  &__left {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media (max-width: 768px) {
      width: 100%;
    }
    .form {
      width: 60%;
      @media (max-width: 768px) {
        width: 80%;
        padding-top: 100px;
      }
      .form-title {
        font-weight: bold;
        font-size: 36px;
        margin-bottom: 40px;
        text-align: center;
      }
      .form-group {
        label {
          color: #a6a6a6;
          font-weight: bold;
          font-size: 14px;
        }
      }
      &__button {
        text-align: center;
        margin-top: 20px;
      }
    }
  }
  &__right {
    width: 55%;
    height: 100vh;
    @media (max-width: 768px) {
      display: none;
    }
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }
}
