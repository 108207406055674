.header {
  height: 60px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 678px) {
    height: unset;
    padding: 5px 10px;
    display: block;
  }

  .dropdown-menu {
    transform: translate3d(-50px, 22px, 0px);
    width: 200px;
  }
  a {
    cursor: pointer;
  }
  .dropdown-toggle {
    line-height: 1.5;
    @media (max-width: 678px) {
      line-height: 40px;
    }
  }
  .mobile__nav {
    @media (max-width: 678px) {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 24px;
    color: #1facca;
    @media (max-width: 678px) {
      display: inline-block;
      margin-left: 10px;
    }

    &__logo {
      margin-left: 20px;
      height: 40px;
    }

    &__name {
      margin-left: 16px;
    }
  }
  &__nav {
    display: flex;
    margin-right: 40px;
    @media (max-width: 678px) {
      margin-right: 10px;
      display: inline-block;
      margin-left: auto;
      float: right;
    }
    &__item {
      font-weight: 500;
      text-decoration: none;
      padding: 0 20px;
      @media (max-width: 678px) {
        padding: 0 10px;
      }
    }

    .disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
      text-decoration: none;
      color: black;
    }
  }
  .dropdown-item,
  .dropdown-item-text {
    padding: 5px 10px;

    &:hover {
      background: unset;
    }
  }
}
