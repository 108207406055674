.tenant-page {
  &__header {
    background: #f9f9fb;
    border-radius: 16px 16px 0 0;
    display: flex;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid #eeeeee;

    &__search {
      margin-left: 20px;
      width: 40%;
    }

    &__right {
      margin-left: auto;

      &__total {
        display: flex;
        justify-content: flex-end;
        margin-right: 20px;
        margin-bottom: 5px;
      }

      &__action {
        display: flex;
        align-items: center;

        .icon {
          cursor: pointer;
          margin-right: 10px;
        }
      }
    }
  }

  &__content {
    .item {
      padding: 20px 20px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eeeeee;

      &__content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &__checkbox {
          margin-left: 14px;
        }

        &__info {
          display: flex;
          margin-left: 24px;
          align-items: center;

          &__icon {
            width: 30px;
            height: 30px;
          }

          &__name {
            margin-left: 16px;

            .name {
              font-size: 20px;
              font-weight: 500;
            }

            .missed {
              color: #a71d2a !important;
            }
          }
        }

        &__action {
          text-align: end;
        }
      }
    }
  }

  &__tenant-modal {
    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }

    &__image {
      display: flex;
      justify-content: center;
    }

    &__form-btn {
      display: flex;
      justify-content: flex-end;

      &__cancel {
        margin-left: 10px;
      }
    }
  }
}

.modal-detail {
  padding: 20px;

  &__row {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dee2e6;

    &__icon {
      cursor: pointer;
    }
  }
  &__attributes {
    &__item {
      &__group {
        display: flex;
        justify-content: space-between;

        button {
          min-width: 160px;
        }
      }

      &__title {
        display: flex;
        align-items: center;

        &__label {
          min-width: 150px;
        }

        .toggle-switch {
          margin-left: 20px;
        }
      }

      &__json-form {
        &__btn-group {
          display: flex;
          justify-content: center;

          button {
            margin-left: 10px;
            margin-top: 10px;
            width: 160px !important;
          }
        }
      }
    }

    &__file-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;

      .form-group {
        margin-bottom: 0 !important;
      }

      &__input {
        display: flex;

        &__upload-button {
          margin-left: 10px;
          width: 24px;
          cursor: pointer;
        }

        &__delete-button {
          margin-left: 10px;
          width: 24px;
          cursor: pointer;
        }

        .disabled {
          cursor: unset;
          opacity: 0.5;
        }
      }
    }
  }
}
