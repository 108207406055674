.contact-page__left__content,
.contact-page__right__content,
.tenant-page__content,
.history-page__content {
  .item {
    background: #fbfbfb;
  }
}

@media (prefers-color-scheme: dark) {
  .App {
    min-height: 100vh;
    color: white;
    background: #2b343b;
  }
  .main-container {
    border-color: #666666 !important ;
  }
  .contact-page__right__header,
  .contact-page__left__header,
  .history-page__header,
  .user-page__header,
  .tenant-page__header {
    background: #1e272e !important;
    border-color: #666666 !important ;
  }
  span {
    color: white !important;
  }
  a {
    color: #40d0e0 !important;
    &:hover {
      color: #1facca !important;
    }
  }
  .icon-white {
    svg {
      g,
      path {
        fill: white !important;
      }
    }
  }
  .modal-detail,
  .modal-detail__history__info,
  .detail-page,
  .message-popup {
    background: #384047 !important;
  }

  .modal-dial-detail {
    background: #333 !important;

    button {
      background: #4a90e2 !important;
      color: white !important;
    }

    &__history {
      &__memo {
        textarea {
          background: #333 !important;
          color: white;
        }

        &__icon {
          select {
            background: #333 !important;
            color: white;
          }
        }
      }
    }
  }

  .denwa-badge,
  .dropdown-menu {
    background: rgb(83, 83, 83) !important;
  }
  .dropdown-item {
    &:hover {
      background: rgb(83, 83, 83) !important;
    }
  }
  .item {
    border-color: #666666 !important;
    &.active {
      background: #004a80 !important;
    }
  }
  .contact-page__left {
    border-color: #666666 !important;
  }
  .contact-page__left__content,
  .contact-page__right__content,
  .tenant-page__content,
  .history-page__content {
    .item {
      background: #384047;
      .item__action {
        background: #111111;
        svg {
          path,
          g {
            fill: white;
          }
        }
      }
    }
  }
  .dimmer {
    background: rgba(0, 0, 0, 0.5) !important;
  }
  .memo,
  .modal-detail__history {
    background: #384047 !important;
  }
  .blacklist-box {
    background: #111 !important;
    svg,
    g,
    path {
      fill: white !important;
    }
  }
  .dial-modal {
    .modal-content,
    input {
      background: #333 !important;
    }
    input,
    span,
    div {
      color: white !important;
    }
    svg,
    g,
    path {
      fill: white !important;
    }
    &__numbers {
      .item {
        &:active {
          background: #004a80 !important;
        }
      }
    }
  }
  .alphabet {
    background: #333 !important;
    border-color: #666666 !important;
  }
  .tenant-page__tenant-modal {
    .modal-content {
      background: #333 !important;
    }
    div,
    h5 {
      color: white !important;
    }
    svg,
    g,
    path {
      fill: white !important;
    }
  }
  .modal-tenant-detail {
    .modal-content {
      background: #333 !important;
    }
    div,
    h5 {
      color: white !important;
    }
    svg,
    g,
    path {
      fill: white !important;
    }
  }
  .message-popup__content {
    background: #111111 !important;
  }
  .chat {
    &.left {
      background: #333333 !important;
    }
  }
  .avatar__action__remove {
    .icon {
      svg {
        rect {
          &:first-child {
            fill: #919191 !important;
          }
        }
      }
    }
  }
  .icon-trash {
    background-image: url("./assets/images/trash-white.svg") !important;
  }
}

.search-input {
  display: flex;
  position: relative;
  &__icon {
    position: absolute;
    left: 10px;
    top: 5px;
  }
  input {
    border-radius: 10px;
    padding-left: 45px;
    border: 1px solid #cfd7e6;
  }
}

.modal-body {
  max-height: calc(100vh - 130px) !important;
  overflow-y: auto;
}

.button-light {
  color: #1facca;
  padding: 10px;
  background: #e2eefa;
  border: 1px solid #1facca;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
}

.button-primary {
  color: white !important;
  padding: 10px;
  background: #1facca !important;
  border: 1px solid #1facca !important;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
}

.denwa-badge {
  display: inline-block;
  padding: 10px 10px;
  border-radius: 50px;
  background: #e2eefa;
  margin-bottom: 10px;
  margin-right: 10px;
  .icon-remove {
    cursor: pointer;
    display: inline-block;
  }
}

#style-1 {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 2px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
}

.dimmer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9999;
}

.color-item {
  display: flex;
  align-items: center;
  height: fit-content;
  border-radius: 6px;
  flex: 1;
  position: relative;
  &__color {
    height: 38px;
    width: 5px;
    position: absolute;
    top: 32px;
  }
  .form-group {
    margin-bottom: 0;
    width: 100%;
  }
}

.modal-user-group {
  height: calc(100vh - 130px) !important;
  overflow-y: auto;
  .title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  .group-name {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
    word-wrap: break-word;
    width: 100%;
  }
  .user-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
    padding: 10px;
    div {
      width: 150px;
      margin-left: 10px;
    }
  }
  .search-input {
    margin-bottom: 20px;
  }
  textarea {
    resize: none;
    height: 100px !important;
  }
}
.memo {
  border-radius: 6px;
  border: 1px solid #cecece;
  padding: 15px;
  word-wrap: break-word;
  background: white;
  margin-top: 20px;
}
.avatar {
  position: relative;
  img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
  &__action {
    position: absolute;
    background: #00000033;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &__remove {
      position: absolute;
      right: -7px;
      bottom: -7px;
    }
    &.icon-white {
      svg {
        width: 30px;
        height: 30px;
        g,
        path {
          fill: white;
        }
      }
    }
  }
  &__name {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #4285f4;
    color: white;
    font-weight: bold;
    font-size: 20px;
  }
}

.blacklist-box {
  padding: 20px;
  border-radius: 16px;
  border: 1px #cecece dashed;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    margin-left: 20px;
    font-size: 20px;
    font-weight: bold;
  }
}

.blacklist-box-action {
  display: flex;
  margin: 30px 0;
  .blacklist-box {
    flex: 1;
    align-items: center;
  }
  &__remove {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 20px;
  }
}

.dial-modal {
  .modal-dialog {
    max-width: 400px !important;
    -webkit-filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
    -moz-filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
    -ms-filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
  }
  .modal-content {
    border-radius: 20px;
  }
  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  input {
    border: none;
    text-align: center;
    font-size: 35px;
    font-weight: bold;
    margin-top: 20px;
  }
  &__numbers {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-gap: 10px;

    .item {
      width: 100px;
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 25px;
      border: 1px solid #cecece;
      text-align: center;
      margin-left: 10px;
      cursor: pointer;
      &:last-child {
        border: none;
      }
      &:active {
        background: #b4dae0;
      }
      @media (max-width: 400px) {
        width: 90%;
        height: 90px;
        margin-left: 5px;
      }
      @media (max-width: 320px) {
        width: 90%;
        height: 75px;
        margin-left: 0;
      }
      &__number {
        font-size: 38px;
        line-height: 28px;
      }
      &.-call {
        border: none;
        background: #43d363;

        @media (max-width: 400px) {
          svg {
            width: 80px;
            height: 80px;
          }
        }
      }
    }
  }
}
.blacklist-csv {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  button {
    width: 100%;
    height: 100%;
  }
}

.message-popup {
  width: 300px;
  position: fixed;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  -webkit-filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
  -moz-filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
  -ms-filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
  border-radius: 16px;
  right: 30px;
  padding: 5px 0;
  z-index: 6;
  background: #eeeeee;
  height: calc(100vh - 20px);
  overflow-y: auto;
  top: 10px;
  display: flex;
  flex-direction: column;
  overflow: unset;
  @media (max-width: 512px) {
    width: calc(100% - 20px);
    margin: 0 10px;
    right: 0;
  }
  &__close {
    cursor: pointer;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
  }
  &__title {
    font-size: 24px;
    padding: 10px 0;
  }
  &__content {
    padding: 10px;
    background: white;
    flex: 1;
    overflow-y: auto;
    &__box {
      margin-top: 16px;
      display: flex;

      &.right {
        flex-direction: row-reverse;
      }
      .chat {
        margin-left: 10px;
        background: #eeeeee;
        padding: 10px;
        border-radius: 16px;
        border-top-left-radius: 5px;
        &.right {
          background: #138eff;
          margin-left: 0;
          margin-right: 10px;
          border-top-right-radius: 5px;
          border-top-left-radius: 16px;
          color: white;
        }
      }
    }
  }
  &__box {
    textarea {
      background-color: unset;
      outline: unset;
      border: none;
      resize: none;
    }
    &__action {
      display: flex;
      align-items: center;
      button {
        height: 30px;
        margin-left: auto;
        margin-right: 10px;
        padding-top: 0;
        padding-bottom: 0;
      }
      .icon {
        cursor: pointer;
        svg {
          g,
          path {
            fill: #999999;
          }
        }
      }
    }
  }
}

.modal-csv {
  @media (min-width: 600px) {
    min-width: 600px;
  }
}
.modal-delete-group {
  .modal-dialog {
    @media (min-width: 600px) {
      min-width: 600px;
    }
  }
}

.icon-trash {
  width: 40px;
  height: 40px;
  background-image: url("./assets/images/trash.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.modal-group {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 650px;
    }
  }
  &__form {
    flex: 1;
    margin-left: 20px;
  }
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-phone-setting {
  .modal-content {
    padding: 0 10px;
  }
  .rjsf {
    padding: 10px 0;
    .form-group {
      &:first-child {
        .container-fluid {
          &:first-child {
            height: calc(100vh - 200px);
            overflow-y: auto;
            .container-fluid {
              height: unset !important;
            }
          }
        }
      }
    }
    .checkbox {
      span {
        margin-left: 10px;
      }
    }
    .row {
      margin-left: 0;
      margin-right: 0;

      .array-item {
        width: 100%;
      }
    }
  }
  .modal-dialog {
    margin: 10px auto !important;
    @media (min-width: 650px) {
      max-width: 650px;
    }
  }
}
.tenant-page__tenant-modal {
  .modal-body {
    max-height: calc(100vh - 200px) !important;
  }
}
.call-type-icon {
  height: 25px;
  width: 25px;
}
