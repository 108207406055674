.user-page {
  .search-input {
    width: 60%;
    margin-left: 20px;
    margin-right: 10px;
  }
  .icon {
    cursor: pointer;
  }
  &__header {
    border-radius: 16px 16px 0 0;
    background: #f9f9fb;
    padding: 0 20px;
    height: 70px;
    display: flex;
    align-items: center;

    .title {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      width: 100%;
    }
    .action {
      position: absolute;
      display: flex;
      right: 30px;
    }
  }
  &__content {
    .item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eee;
      padding: 20px;
      &__checkbox {
        width: 70px;
        @media (max-width: 500px) {
          width: unset;
        }
      }
      &__name {
        width: calc(50% - 70px);
        @media (max-width: 500px) {
          width: unset;
          width: 80px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-left: 5px;
        }
      }
      &__email {
        width: calc(50% - 70px);
        display: flex;
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-left: 10px;
        overflow: hidden;
        svg {
          flex: none;
        }
        @media (max-width: 500px) {
          width: calc(100% - 150px);
        }
      }
      &__action {
        width: 50px;
        margin-left: auto;
        @media (max-width: 500px) {
          width: unset;
          margin-left: auto;
        }
        div {
          cursor: pointer;
        }
      }
    }
  }
}
