.toggle-switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 32px;
  margin-bottom: 0;

  &__input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .toggle-switch__slider {
      background-color: #3da9fc;
    }

    &:focus + .toggle-switch__slider {
      box-shadow: 0 0 1px #3da9fc;
    }

    &:checked + .toggle-switch__slider::before {
      transform: translateX(20px);
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    transition: .4s;
    border-radius: 36px;
    border: solid 1px #dbdbdb;

    &::before {
      position: absolute;
      content: "";
      width: 28px;
      height: 28px;
      left: 0px;
      bottom: 1px;
      background-color: #fff;
      transition: .4s;
      border-radius: 50%;
      box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
      border: solid 1px #dbdbdb;
    }
  }
}