.history-page {
  .mobile {
  }

  &__header {
    background: #f9f9fb;
    border-radius: 16px 16px 0 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid #eeeeee;

    &__search {
      margin-left: 20px;
      width: 40%;
    }

    &__call-type {
      margin-left: 20px;

      button {
        background-color: #ffffff;
      }

      .active {
        background-color: #eaeaea;
      }
    }

    &__action {
      margin-left: auto;

      .icon {
        cursor: pointer;
      }
    }
  }

  &__content {
    .item {
      padding: 15px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eeeeee;

      &__content {
        width: 100%;
        display: flex;
        align-items: center;
        //justify-content: space-between;
        cursor: pointer;

        &__checkbox {
          margin-left: 14px;
        }

        &__info {
          display: flex;
          margin-left: 24px;
          min-height: 54px;
          align-items: center;
          width: 40%;

          &__icon {
            width: 30px;
            height: 30px;
          }

          &__name {
            margin-left: 16px;

            .name {
              font-size: 20px;
              font-weight: 500;
            }

            .missed {
              color: #a71d2a !important;
            }
          }
        }

        &__time {
          margin-left: 10px;
          width: 18%;
        }

        &__duration {
          margin-left: 10px;
          width: 10%;
        }

        &__memo {
          margin-left: 10px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 40%;

          @media (max-width: 768px) {
            display: none;
          }
        }
      }

      @media (max-width: 768px) {
        &__content {
          &__checkbox {
            margin-left: 6px;
          }

          &__info {
            margin-left: 10px;
            width: 60%;

            &__name {
              margin-left: 6px;
            }
          }

          &__time {
            margin-left: 10px;
            width: 40%;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .modal-detail {
    background: #f9f9fb;
    width: 500px;
    position: fixed;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    box-shadow: 0 5px 20px rgba(28, 143, 168, 0.08);
    border-radius: 16px;
    right: 30px;
    padding: 20px;
    z-index: 2;
    height: calc(100vh - 120px);
    overflow-y: auto;

    &__close {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      top: 5px;
      right: 20px;
    }

    &__contact {
      display: flex;
      align-items: center;

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover;
      }

      &__info {
        margin-left: 16px;

        &__name {
          font-weight: bold;
          font-size: 20px;
        }
      }
    }

    &__history {
      margin-top: 40px;
      background-color: #ffffff;
      padding: 24px;

      &__info {
        background: #f9f9fb;
        margin-top: 10px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;

        &__contact {
          font-size: 16px;
          font-weight: bold;

          .missed {
            color: #a71d2a !important;
          }
        }
      }

      &__record-file {
        display: flex;
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #eeeeee;

        &__file-name {
          margin-left: 6px;
        }
      }

      &__memo {
        margin-top: 16px;
        padding-top: 36px;
        position: relative;

        &__icon {
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
          display: flex;
        }
      }
    }
  }
}
