.popup-calling {
  padding-right: 0 !important;
  .modal-dialog {
    width: 70% !important;
    max-width: 800px !important;

    @media (max-width: 1000px) {
      width: calc(100% - 16px) !important;
    }
  }
  .modal-content {
    border-radius: 100px;
  }
  &__content {
    display: flex;
    align-items: center;

    &__status {
      width: 100px;
      margin: 0 5px;
      @media (max-width: 675px) {
        display: none;
      }
    }
    &__timer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
      .timer {
        margin-right: 50px;
      }
      @media (max-width: 986px) {
        .timer {
          margin-right: 10px;
        }
      }
      @media (max-width: 500px) {
        flex-direction: column;
      }
    }
    &__icons {
      display: flex;
    }
    &__icon {
      cursor: pointer;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #eeeeee;
      margin: 0px 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 600px) {
        width: 35px;
        height: 35px;
      }
      &.active {
        background: #4285f4;
      }
      &.-danger {
        background: #f43808;
        svg {
          width: 32px !important;
          @media (max-width: 500px) {
            width: 26px !important;
          }
        }
      }
      &.-accept {
        background: #43d363;

        svg {
          width: 25px !important;
          @media (max-width: 500px) {
            width: 20px !important;
          }
        }
      }
    }
    &__info {
      width: 40%;
      margin-left: 30px;
      @media (max-width: 768px) {
        margin-left: 10px;
      }
      @media (max-width: 500px) {
        width: calc(100% - 220px);
      }
      .name {
        font-weight: bold;
        font-size: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .company {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
