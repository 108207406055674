.contact-page {
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  .public-color {
    height: 30px;
    margin: 0 10px;
    width: 3px;
    background: #138eff;
  }
  .private-color {
    height: 30px;
    margin: 0 10px;
    width: 3px;
    background: #8d8d8d;
  }
  .alphabet {
    position: fixed;
    padding: 16px;
    background: #f9f9fb;
    border: 1px solid #eeeeee;
    border-radius: 100px;
    right: 30px;
    top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 170px);
    overflow-y: auto;
    z-index: 5;
    @media (max-width: 500px) {
      right: 10px;
      padding: 10px;
    }
    .item {
      font-weight: bold;
      font-size: 16px;
      color: #4285f4;
      opacity: 0.8;
      &:hover,
      &.-active {
        opacity: 1;
      }
    }
  }
  .modal-detail {
    width: 512px;
    position: fixed;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    -webkit-filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
    -moz-filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
    -ms-filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
    border-radius: 16px;
    right: 30px;
    padding: 40px 20px 20px 20px;
    z-index: 6;
    background: #eeeeee;
    height: calc(100vh - 20px);
    overflow-y: auto;
    top: 10px;
    @media (max-width: 512px) {
      width: calc(100% - 20px);
      margin: 0 10px;
      right: 0;
    }
    .text-group {
      color: #999999;
      font-size: 14px;
      text-align: center;
    }
    .text-name {
      font-weight: bold;
      font-size: 30px;
      text-align: center;
    }
    &__history-call {
      &__title {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        border-bottom: 1px solid #cecece;
        cursor: pointer;
        margin-bottom: 20px;
      }
      &__items {
        display: flex;
        flex-direction: column;
        align-items: center;

        .item {
          width: 100%;
          display: flex;
          margin-top: 10px;
          &__type {
            width: 50px;
          }
          &__date {
            width: 70px;
          }
          &__number {
            width: 150px;
          }
          &__memo {
            flex: 1;
          }
        }
      }
    }
    &__add-address {
      margin: 20px 0 10px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        color: #1facca;
        font-weight: bold;
      }
      svg {
        path,
        g {
          fill: #1facca;
        }
      }
    }
    &__close {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      top: 5px;
      right: 10px;
    }
    &__submit {
      text-align: center;
      margin-top: 20px;
    }
    &__avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0;
      border-bottom: 1px solid #cecece;
    }
    &__organization {
      padding: 20px 0;
      border-bottom: 1px solid #cecece;

      &__group {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        div {
          flex: none;
          max-height: 32px;
        }
      }
    }
    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .button-light {
        // width: 100px;
        text-align: center;
      }
      &__group {
        display: flex;
        align-items: center;
        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          object-fit: cover;
          flex: none;
        }
        &__detail {
          margin-left: 10px;
        }
      }
    }
    &__attributes {
      padding: 20px 0;
      border-bottom: 1px solid #cecece;
      &__item {
        display: flex;
        align-items: center;
        .button-primary {
          margin-left: auto;
          flex: none;
        }
        &__title {
          width: 90px;
          color: #999999;
          font-size: 14px;
        }
        &__star {
          margin-left: 5px;
          padding-bottom: 5px;
          cursor: pointer;
          svg {
            width: 20px;
            height: 20px;
          }
        }
        &__content {
          margin-left: 20px;
          font-size: 14px;
          .form-group {
            margin-bottom: 0;
          }
          &__group {
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            &__icon {
              cursor: pointer;
            }

            .form-control {
              width: 320px;
              @media (max-width: 500px) {
                width: calc(100% - 60px);
              }
            }
          }
        }
        &.border-top {
          padding: 10px 0;
          border-top: 1px solid #eeeeee;
        }
      }
    }

    &__address {
      margin-top: 20px;
      &__title {
        font-weight: bold;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        &__icon {
          cursor: pointer;
        }
      }
    }
  }
  &__left {
    width: 30%;
    flex: none;
    border-right: 1px solid #eeeeee;
    max-height: calc(100vh - 70px);
    transition: width 0.3s;
    @media (max-width: 1024px) {
      width: 100%;
      border-right: none;
      max-height: unset;
    }
    &.-hide {
      visibility: hidden;
      width: 0;
      .item__action {
        visibility: hidden;
      }
      @media (max-width: 1024px) {
        height: 0;
      }
    }
    &__header {
      background: #f9f9fb;
      border-radius: 16px 0 0 0;
      padding: 0 20px 0 43px;
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eeeeee;
      @media (max-width: 1024px) {
        border-radius: 16px 16px 0 0;
        padding: 0 20px 0 25px;
      }
      &__action {
        display: flex;

        .icon {
          cursor: pointer;
        }
      }
    }
    &__content {
      max-height: calc(100vh - 140px);
      overflow-y: auto;
      @media (max-width: 1024px) {
        max-height: unset;
        overflow-y: unset;
      }
      .item {
        position: relative;
        padding: 20px;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        align-items: center;
        cursor: pointer;
        @media (max-width: 1024px) {
          padding: 20px 20px 20px 2px;
        }
        &.active {
          background: rgb(180, 218, 224);
        }
        &:last-child {
          border-bottom: none;
          border-bottom-left-radius: 16px;
          @media (max-width: 1024px) {
            border-bottom-left-radius: 0;
          }
        }
        &:hover {
          .item__action {
            visibility: visible;
          }
        }
        &__toggle {
          position: absolute;
          left: 10px;
          display: flex;
          align-items: center;
          svg {
            width: 12px;
            height: 12px;

            @media (max-width: 1024px) {
              width: 10px;
              height: 10px;
            }
          }
          @media (max-width: 1024px) {
            left: 0;
          }
        }
        span {
          word-wrap: break-word;
          width: calc(100% - 20px);

          @media (max-width: 1024px) {
            width: calc(100% - 150px) !important;
          }
          @media (max-width: 500px) {
            width: calc(100% - 120px) !important;
          }
        }
        &.index_1 {
          padding-left: 30px;
          @media (max-width: 1024px) {
            padding-left: 10px;
          }
          .item__toggle {
            left: 20px;
            @media (max-width: 1024px) {
              left: 8px;
            }
          }
          span {
            word-wrap: break-word;
            width: calc(100% - 30px);

            @media (max-width: 1024px) {
              width: calc(100% - 180px) !important;
            }
            @media (max-width: 500px) {
              width: calc(100% - 110px) !important;
            }
          }
        }
        &.index_2 {
          padding-left: 40px;
          @media (max-width: 1024px) {
            padding-left: 18px;
          }
          .item__toggle {
            left: 30px;
            @media (max-width: 1024px) {
              left: 16px;
            }
          }
          span {
            word-wrap: break-word;
            width: calc(100% - 40px);

            @media (max-width: 1024px) {
              width: calc(100% - 200px) !important;
            }
            @media (max-width: 500px) {
              width: calc(100% - 130px) !important;
            }
          }
        }
        &.index_3 {
          padding-left: 50px;
          @media (max-width: 1024px) {
            padding-left: 26px;
          }
          .item__toggle {
            left: 40px;
            @media (max-width: 1024px) {
              left: 24px;
            }
          }
          span {
            word-wrap: break-word;
            width: calc(100% - 50px);

            @media (max-width: 1024px) {
              width: calc(100% - 220px) !important;
            }
            @media (max-width: 500px) {
              width: calc(100% - 150px) !important;
            }
          }
        }
        &__action {
          display: flex;
          margin-left: auto;
          visibility: hidden;
          position: absolute;
          right: 10px;
          background: white;
          padding: 5px 0;
          border-radius: 6px;

          @media (max-width: 1024px) {
            right: 20px;
            margin-right: 50px;
            visibility: visible;
            position: unset;
          }
          @media (max-width: 500px) {
            margin-right: 16px;
          }
          div {
            cursor: pointer;
            margin: 0 3px;
            opacity: 0.6;
            border-radius: 6px;

            &:hover {
              opacity: 1;
            }
          }
          .message {
            position: relative;
            &__unread {
              position: absolute;
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: crimson;
              color: white;
              border-radius: 50%;
              top: 10px;
              left: 5px;
              font-size: 10px;
              border: 2px solid white;
            }
          }
        }
      }
    }
  }
  &__right {
    width: 70%;
    flex: none;
    max-height: calc(100vh - 70px);
    transition: width 0.3s;
    @media (max-width: 1024px) {
      width: 100%;
      max-height: unset;
    }
    &.-hide {
      @media (min-width: 500px) {
        width: 100%;
      }
    }
    &__header {
      background: #f9f9fb;
      border-radius: 0 16px 0 0;
      padding: 0 20px 0 43px;
      display: flex;
      align-items: center;
      height: 70px;
      border-bottom: 1px solid #eeeeee;
      @media (max-width: 1024px) {
        border-radius: 0;
        padding: 0 20px 0 25px;
      }
      &__search {
        margin-left: 20px;
        width: 60%;
      }
      &__action {
        margin-left: auto;
        display: flex;

        .icon {
          cursor: pointer;
        }
      }
    }
    &__content {
      max-height: calc(100vh - 140px);
      overflow-y: auto;
      scroll-behavior: smooth;
      --scroll-behavior: smooth;
      @media (max-width: 1024px) {
        max-height: unset;
        overflow-y: unset;
      }
      .item {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eeeeee;
        cursor: pointer;
        @media (max-width: 1024px) {
          padding: 20px 10px 20px 2px;
        }
        &.active {
          background: rgb(180, 218, 224);
        }
        &.favorite {
          padding-top: 5px;
          padding-bottom: 5px;
          @media (max-width: 1024px) {
            padding-left: 10px;
          }
        }
        &:last-child {
          border-bottom: none;
        }
        .info {
          margin: 0 20px;
          display: flex;
          align-items: center;
          width: 30%;
          position: relative;

          @media (max-width: 768px) {
            width: 47% !important;
          }
          &__unread {
            position: absolute;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: crimson;
            color: white;
            border-radius: 50%;
            top: 40px;
            left: 40px;
            font-size: 10px;
            border: 2px solid white;
          }
          &__name {
            margin-left: 10px;
            width: calc(100% - 70px);
            &__groups {
              display: none;
              @media (max-width: 500px) {
                display: block;
              }
            }
            .name {
              font-weight: bold;
              font-size: 20px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .phonetic-name {
              color: #999999;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              font-size: 14px;
            }
          }
          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            object-fit: cover;
            flex: none;
          }
        }
        .groups {
          width: calc(30% - 40px);
          line-height: 24px;
          margin-right: 10px;
          div {
            margin: 5px;
          }
          &__item {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
          }
          @media (min-width: 1024px) and (max-width: 1200px) {
            width: 20%;
          }
          @media (max-width: 500px) {
            width: 100%;
            margin-top: 10px;
          }
        }
        .phones {
          line-height: 24px;
          width: calc(30% - 40px);
          @media (min-width: 1024px) and (max-width: 1300px) {
            width: calc(40% - 40px);
            flex: 1;
          }
          @media (max-width: 500px) {
            margin-top: 10px;
            flex: 1;
          }
          &__item {
            display: flex;
            font-size: 14px;
            align-items: center;
            .type {
              color: #999999;
              width: 70px;
            }
            .message {
              width: 70px;
              position: relative;
            }
            a {
              color: #138eff;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }

    &__dial-content {
      max-height: calc(100vh - 140px);
      overflow-y: auto;
      scroll-behavior: smooth;
      --scroll-behavior: smooth;

      .item {
        padding: 15px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eeeeee;

        &__content {
          width: 100%;
          display: flex;
          align-items: center;
          //justify-content: space-between;
          cursor: pointer;

          &__checkbox {
            margin-left: 14px;
          }

          &__info {
            display: flex;
            margin-left: 24px;
            min-height: 54px;
            align-items: center;
            width: 40%;

            //&__icon {
            //  svg {
            //    width: 30px;
            //    height: 30px;
            //  }
            //}
            &__name {
              margin-left: 16px;

              .name {
                font-size: 20px;
                font-weight: 500;
              }

              .missed {
                color: #a71d2a !important;
              }
            }
          }

          &__time {
            margin-left: 10px;
            width: 18%;
          }

          &__duration {
            margin-left: 10px;
            width: 10%;
          }

          &__memo {
            margin-left: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 40%;

            @media (max-width: 768px) {
              display: none;
            }
          }
        }

        @media (max-width: 768px) {
          &__content {
            &__checkbox {
              margin-left: 6px;
            }

            &__info {
              margin-left: 10px;
              width: 60%;

              &__name {
                margin-left: 6px;
              }
            }

            &__time {
              margin-left: 10px;
              width: 40%;
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }

  .modal-dial-detail {
    background: #f9f9fb;
    width: 500px;
    position: fixed;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    box-shadow: 0 5px 20px rgba(28, 143, 168, 0.08);
    border-radius: 16px;
    right: 30px;
    padding: 20px;
    z-index: 2;
    height: calc(100vh - 120px);
    overflow-y: auto;

    &__close {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      top: 5px;
      right: 20px;
    }

    &__contact {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__left {
        display: flex;

        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          object-fit: cover;
        }

        &__info {
          margin-left: 16px;

          &__name {
            font-weight: bold;
            font-size: 18px;
          }
        }
      }

      &__right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        &__talking-date {
          font-size: 14px;
          display: flex;
          justify-content: flex-end;
        }

        &__talking-duration {
          font-size: 14px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    &__history {
      margin-top: 40px;

      &__memo {
        margin-top: 16px;
        padding-top: 36px;
        position: relative;

        &__icon {
          position: absolute;
          right: 10px;
          top: -10px;
          cursor: pointer;
          display: flex;

          &__save {
            margin-left: 10px;
          }
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      align-items: center;
      margin-bottom: 20px;

      &__toggle {
        display: flex;
        align-items: center;
      }

      &__page {
        &__next {
          margin-left: 10px;
        }
      }

      button {
        border: 1px solid #999999;
        background-color: rgba(153, 153, 153, 0.3);
      }
    }

    &__call-out {
      &__item {
        padding: 15px 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eeeeee;

        &__content {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;

          &__info {
            display: flex;
            //margin-left: 24px;
            min-height: 54px;
            align-items: center;
            width: 40%;

            &__icon {
              width: 30px;
              height: 30px;
            }

            &__name {
              margin-left: 6px;

              .name {
                font-size: 20px;
                font-weight: 500;
              }

              .missed {
                color: #a71d2a !important;
              }
            }
          }
        }

        @media (max-width: 768px) {
          &__content {
            &__info {
              margin-left: 10px;
              width: 60%;

              &__name {
                margin-left: 6px;
              }
            }
          }
        }
      }
    }
  }
}
