.detail-container {
  display: flex;
  justify-content: center;

  .login-page__left {
    width: 70%;
    margin-top: 30px;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }
}

.detail-page {
  padding: 20px;
  max-width: 1024px;
  width: 100%;
  z-index: 2;
  display: flex;
  background: #eeeeee;
  margin: 20px 0;
  border-radius: 12px;
  -webkit-filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
  -moz-filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
  -ms-filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 40px 30px;
    max-width: 512px;
    margin: 10px;
  }
  .detail-mobile {
    display: none;
    @media (max-width: 1024px) {
      display: block;
    }
  }
  .detail-desktop {
    @media (max-width: 1024px) {
      display: none;
    }
  }
  form {
    width: 100%;
  }
  &__left {
    width: 50%;
    padding-right: 30px;
    border-right: 1px solid #cecece;
    @media (max-width: 1024px) {
      width: 100%;
      padding: 0;
      border-right: none;
    }
    &__top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid #cecece;
      margin-bottom: 20px;
    }
    .text-group {
      color: #999999;
      text-align: center;
    }
    .text-name {
      font-weight: bold;
      font-size: 30px;
      text-align: center;
    }
  }
  &__right {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    @media (max-width: 1024px) {
      width: 100%;
      padding: 0;
    }
    .button-light {
      margin-left: auto;
      width: 100px;
      text-align: center;
      flex: none;
    }
  }

  &__add-address {
    margin: 20px 0 10px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      color: #1facca;
      font-weight: bold;
    }
    svg {
      path,
      g {
        fill: #1facca;
      }
    }
  }
  &__close {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 5px;
    right: 20px;
  }
  &__submit {
    text-align: center;
    margin-top: 20px;
  }
  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .button-light {
      width: 100px;
      text-align: center;
      flex: none;
    }
    &__avt {
      display: flex;
      align-items: center;
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover;
        flex: none;
      }
      &__detail {
        margin-left: 10px;
      }
      .text-group {
        color: #999999;
      }
      .text-name {
        font-weight: bold;
        font-size: 30px;
      }
    }
  }
  &__attributes {
    margin-top: 30px;
    @media (max-width: 1024px) {
      margin-top: 20px;
      padding-top: 20px;
    }
    &__item {
      display: flex;
      align-items: center;
      &__title {
        width: 90px;
        color: #999999;
        display: flex;
      }
      &__content {
        margin-left: 20px;
        .form-group {
          margin-bottom: 0;
        }
        &__group {
          margin-bottom: 10px;
          display: flex;
          align-items: center;

          &__icon {
            cursor: pointer;
          }
        }
      }
      &.border-top {
        padding: 10px 0;
        border-top: 1px solid #cecece;
      }
    }
  }
  &__history {
    &__title {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      border-bottom: 1px solid #cecece;
      cursor: pointer;
      margin-bottom: 20px;
    }
    &__items {
      display: flex;
      flex-direction: column;
      align-items: center;

      .item {
        width: 100%;
        display: flex;
        margin-top: 10px;
        &__type {
          width: 50px;
        }
        &__date {
          width: 70px;
        }
        &__number {
          width: 150px;
        }
        &__memo {
          flex: 1;
        }
      }
    }
  }
  &__address {
    margin-top: 20px;
    @media (max-width: 1024px) {
      margin-top: 20px;
      border-top: 1px solid #cecece;
      padding-top: 20px;
    }

    &__title {
      font-weight: bold;
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__icon {
        cursor: pointer;
      }
    }
  }
}
